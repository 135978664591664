<template>
  <b-form-group>
    <template v-if="label" v-slot:label>
      <label>
        {{ label }}

        <span v-if="required" class="text-danger"> *</span>
      </label>
    </template>

    <b-input-group>
      <b-form-datepicker
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :state="validationState"
        label-help="Use as setas para navegação"
        hide-header
        @change="$emit('change', $event)"
        @input="$emit('input', $event)"
      />

      <b-form-invalid-feedback>
        {{ validationMessage }}
      </b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
    },
    spellcheck: {
      type: Boolean,
      required: false,
    },
    copyable: {
      type: Boolean,
      required: false,
    },
    rotateIcon: {
      type: String,
      required: false,
    },
    appendIcon: {
      type: String,
      required: false,
    },
    appendVariant: {
      type: String,
      required: false,
      default: "primary",
    },
  },
  data() {
    return {
      toggleEye: true,
    };
  },
  computed: {
    validationState() {
      return this.errors[0] ? false : null;
    },
    validationMessage() {
      return this.errors[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  cursor: pointer;
}
</style>
