<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Clientes</h4>

          <search-input
            v-model="searchClient"
            :is-loading-search="isLoadingSearch"
            @enter="search"
          />
        </div>

        <div class="d-flex justify-content-end mb-2">
          <b-button variant="primary" @click="openCreateClientModal">
            ADICIONAR CLIENTE B2B
          </b-button>
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table
            :items="formatedClients"
            :fields="clientsFields"
            :busy="isLoading || isLoadingSearch"
            bordered
            striped
            hover
            @row-clicked="changeRoute"
          >
            <template #cell(actions)="row">
              <b-button
                size="sm"
                variant="primary"
                title="Editar informações de cliente"
                @click="changeRoute(row.item)"
              >
                <b-icon icon="pencil"> </b-icon>
              </b-button>
              <b-button
                :title="
                  row.item.b2b
                    ? 'Cancelar B2B para esse cliente'
                    : 'Ativar B2B para esse cliente'
                "
                size="sm"
                :variant="row.item.b2b ? 'danger' : 'success'"
                class="ml-2"
                @click="openActiveB2bModal(row.item.id, row.item.b2b)"
              >
                <b-icon :icon="row.item.b2b ? 'x-circle' : 'check-circle'">
                </b-icon>
              </b-button>
            </template>
          </b-table>

          <b-button v-if="showButton" @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </b-card-text>

        <b-modal
          v-model="newClientModal"
          size="xl"
          title="Adicione as informações do cliente"
          hide-footer
        >
          <b-form @submit.prevent="createClient">
            <b-row>
              <b-col md="4">
                <form-input
                  v-model="newClientInfo.cpfCnpj"
                  icon="person-lines-fill"
                  label="CPF/CNPJ"
                  required
                  :errors="errors['cpfCnpj']"
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="newClientInfo.name"
                  icon="person-lines-fill"
                  label="Razão Social"
                  required
                  :errors="errors['name']"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="newClientInfo.email"
                  icon="at"
                  label="E-mail"
                  required
                  :errors="errors['email']"
                />
              </b-col>
              <b-col md="4">
                <form-date
                  v-model="newClientInfo.birthDate"
                  label="Data de abertura:"
                  placeholder="Data de abertura"
                  required
                  :errors="errors['birthDate']"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="newClientInfo.phone1"
                  icon="telephone"
                  label="Telefone"
                  required
                  :errors="errors['phone1']"
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="newClientInfo.phone2"
                  icon="telephone"
                  label="Telefone 2"
                  :errors="errors['phone2']"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <form-select
                  v-model="newClientInfo.discountTableId"
                  :options="discountTableList"
                  icon="coin"
                  textField="description"
                  label="Tabela de desconto"
                  :errors="errors['discountTableId']"
                />
              </b-col>

              <b-col>
                <form-number
                  v-model="newClientInfo.extraDiscount"
                  icon="percent"
                  label="Desconto adicional"
                  :errors="errors['extraDiscount']"
                />
              </b-col>

              <b-col>
                <form-number
                  v-model="newClientInfo.admDiscount"
                  icon="percent"
                  label="Desconto administrativo"
                  :errors="errors['admDiscount']"
                />
              </b-col>

              <b-table
                :items="newClientInfo.client_address"
                :fields="newAddressField"
                caption-top
              >
                <template #table-caption>
                  <div
                    class="d-flex justify-content-center"
                    v-if="errors['client_address']"
                  >
                    <span class="text-secondary">
                      {{ errors["client_address"][0] }}
                    </span>
                  </div>
                </template>
                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    class="mr-1"
                    :variant="row.item.main ? 'success' : 'dark'"
                    @click="turnMain(row.index)"
                  >
                    <b-icon :icon="row.item.main ? 'check-lg' : 'x'"></b-icon>
                  </b-button>
                  <b-button
                    size="sm"
                    variant="danger"
                    @click="retireAddress(row.index)"
                  >
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-row>

            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                class="mr-2"
                @click="openAddNewAddressModal"
                >NOVO ENDEREÇO
              </b-button>
              <b-button variant="primary" type="submit">SALVAR</b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          v-model="addNewAddressModal"
          title="Insira as informações de endereço"
          size="xl"
          hide-footer
        >
          <b-form @submit.prevent="addNewAddressToList">
            <b-row>
              <b-col md="4">
                <form-input
                  v-model="newAddress.zipCode"
                  icon="signpost-split"
                  label="CEP:"
                  required
                  @change="getInfoByCep"
                  @enter="getInfoByCep"
                ></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="newAddress.address"
                  icon="signpost-split"
                  label="Endereço:"
                  required
                ></form-input>
              </b-col>
              <b-col md="3">
                <form-input
                  v-model="newAddress.number"
                  icon="mailbox"
                  label="Numero:"
                  required
                ></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="newAddress.district"
                  icon="map"
                  label="Bairro:"
                  required
                ></form-input>
              </b-col>

              <b-col>
                <form-input
                  v-model="newAddress.city"
                  icon="pin-map"
                  label="Cidade:"
                  required
                ></form-input>
              </b-col>
              <b-col md="2">
                <form-input
                  v-model="newAddress.state"
                  icon="signpost-split"
                  label="Estado:"
                  required
                ></form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <form-input
                  v-model="newAddress.references"
                  icon="signpost-split"
                  label="Referência:"
                  required
                ></form-input>
              </b-col>
              <b-col>
                <form-input
                  v-model="newAddress.complement"
                  icon="card-text"
                  label="Complemento:"
                ></form-input>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end">
              <b-button variant="primary" type="submit">ADICIONAR</b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          v-model="activeB2bModal"
          :title="this.b2b ? 'Cancelar' : 'Ativar'"
          hide-footer
        >
          <div>
            {{
              this.b2b
                ? "Deseja cancelar o acesso a plataforma B2B para esse cliente?"
                : "Deseja permitir o acesso a plataforma B2B para esse cliente?"
            }}
          </div>

          <div class="mt-3 d-flex justify-content-end">
            <b-button
              :variant="this.b2b ? 'danger' : 'success'"
              @click="activateB2b"
            >
              {{ this.b2b ? "CANCELAR" : "ATIVAR" }}
            </b-button>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { maskBr } from "js-brasil";

import FormInput from "../../components/form/FormInput.vue";
import FormDate from "../../components/form/FormDate.vue";
import SearchInput from "../../components/shared/SearchInput.vue";
import FormSelect from "../../components/form/FormSelect.vue";
import FormNumber from "../../components/form/FormNumber.vue";

import http from "../../plugins/http";
import axios from "axios";

export default {
  components: { FormInput, SearchInput, FormSelect, FormNumber, FormDate },
  data() {
    return {
      activeB2bModal: false,
      b2b: null,
      clientId: null,
      searchClient: "",
      listClients: [],
      page: 2,
      isLoadingSearch: false,
      isLoading: false,
      showButton: true,
      errors: {},
      clientsFields: [
        {
          key: "name",
          label: "Nome",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "cpfCnpj",
          label: "Cpf/Cnpj",
          sortable: true,
        },
        {
          key: "phone1",
          label: "Telefone 1",
          sortable: true,
        },
        {
          key: "phone2",
          label: "Telefone 2",
          sortable: true,
        },
        {
          key: "actions",
          label: "Ações",
        },
      ],
      addNewAddressModal: false,
      newAddressField: [
        {
          key: "address",
          label: "Endereço",
        },
        {
          key: "number",
          label: "Número",
        },
        {
          key: "complement",
          label: "Complemento",
        },
        {
          key: "district",
          label: "Bairro",
        },
        {
          key: "zipCode",
          label: "CEP",
        },
        {
          key: "references",
          label: "Referência",
        },
        {
          key: "state",
          label: "Estado",
        },
        {
          key: "city",
          label: "Cidade",
        },
        {
          key: "actions",
          label: "Ações",
        },
      ],
      newClientModal: false,
      discountTableList: [{ id: 0, description: "SEM TABELA DE DESCONTO" }],
      newClientInfo: {
        name: "",
        email: "",
        phone1: "",
        phone2: "",
        cpfCnpj: "",
        birthDate: "",
        extraDiscount: "",
        admDiscount: "",
        discountTableId: 0,
        client_address: [],
      },
      newAddress: {
        address: "",
        number: "",
        complement: "",
        district: "",
        zipCode: "",
        references: "",
        state: "",
        city: "",
        main: false,
      },
    };
  },
  computed: {
    formatedClients() {
      return this.listClients.map((client) => {
        return {
          ...client,
          cpfCnpj: formatCpfCnpj(client.cpfCnpj),
          phone1:
            client.phone1.length > 10
              ? maskBr.celular(client.phone1)
              : maskBr.telefone(client.phone1),
          phone2: client.phone2
            ? client.phone2.length > 10
              ? maskBr.celular(client.phone2)
              : maskBr.telefone(client.phone2)
            : "Não Registrado",
        };
      });
    },
  },
  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    async openCreateClientModal() {
      const { data } = await http(`discount`);

      this.discountTableList = [...this.discountTableList, ...data];

      this.newClientInfo = {
        name: "",
        email: "",
        phone1: "",
        phone2: "",
        cpfCnpj: "",
        birthDate: "",
        client_address: [],
        discountTableId: 0,
        admDiscount: "",
        extraDiscount: "",
      };

      this.errors = {};
      this.newClientModal = true;
    },

    turnMain(item) {
      this.newClientInfo.client_address = this.newClientInfo.client_address.map(
        (info) => {
          return {
            ...info,
            main: false,
          };
        }
      );
      this.newClientInfo.client_address[item].main = true;
    },

    openActiveB2bModal(id, b2b) {
      this.b2b = b2b;
      this.clientId = id;
      this.activeB2bModal = true;
    },

    addNewAddressToList() {
      if (!this.newClientInfo.client_address.length) {
        this.newAddress.main = true;
      }

      this.newClientInfo.client_address.push(this.newAddress);
      this.addNewAddressModal = false;
    },

    openAddNewAddressModal() {
      this.newAddress = {
        address: "",
        number: "",
        complement: "",
        district: "",
        zipCode: "",
        references: "",
        state: "",
        city: "",
        main: false,
      };
      this.addNewAddressModal = true;
    },

    retireAddress(index) {
      this.newClientInfo.client_address.splice(index, 1);
    },

    async getMore() {
      const { page, searchClient } = this;

      if (this.searchClient) {
        this.isLoadingSearch = true;

        const { data } = await http(`/client`, {
          params: {
            search: searchClient,
            page: page,
          },
        });

        this.listClients = [...this.listClients, ...data];
        this.isLoadingSearch = false;
        this.showButton = data.length === 20;
      } else {
        this.isLoading = true;
        const { data } = await http(`/client`, {
          params: {
            page: page,
          },
        });
        this.listClients = [...this.listClients, ...data];
        this.isLoading = false;
        this.showButton = data.length === 20;
      }

      this.page = page + 1;
    },
    async search() {
      this.page = 2;
      if (this.searchClient) {
        this.isLoadingSearch = true;
        const { data } = await http(`/client`, {
          params: {
            search: this.searchClient,
          },
        });
        this.listClients = data;
        this.isLoadingSearch = false;
        this.showButton = data.length === 20;
      } else {
        this.isLoading = true;
        const { data } = await http(`/client`);
        this.listClients = data;
        this.isLoading = false;
        this.showButton = data.length === 20;
      }
    },
    async changeRoute(item) {
      const { id } = item;
      this.$router.push(`/cliente/${id}/detalhe`);
    },
    async createClient() {
      this.errors = {};
      let form = {};

      try {
        form = this.newClientInfo;

        if (!this.newClientInfo.discountTableId) {
          delete form.discountTableId;
        }

        const { data } = await http.post("/client/", form);

        this.listClients.push(data);
        this.newClientModal = false;

        this.setToastedSuccess({
          message: "Cliente adicionado com sucesso",
        });
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },

    async activateB2b() {
      try {
        this.activeB2bModal = false;
        this.setOverlay(true);
        const { data } = await http.put(`/client/activeB2b/${this.clientId}`, {
          b2b: !this.b2b,
        });

        const index = this.listClients.findIndex(
          (element) => element.id === data.id
        );
        this.listClients.splice(index, 1, data);
      } catch (error) {
        this.setToastedError({
          message: error.response.data.error,
        });
      }
      this.setOverlay(false);
    },

    async getInfoByCep(cep) {
      const { data } = await axios(
        `https://brasilapi.com.br/api/cep/v1/${cep}`
      );
      this.newAddress = {
        ...this.newAddress,
        address: data.street,
        district: data.neighborhood,
        zipCode: data.cep,
        state: data.state,
        city: data.city,
      };
    },
  },

  async created() {
    this.setOverlay(true);
    const { data } = await http(`/client`);
    this.listClients = data;
    this.showButton = data.length === 20;
    this.setOverlay(false);
  },
};
</script>
