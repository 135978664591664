<template>
  <b-form-group>
    <template v-if="label" v-slot:label>
      <label>
        {{ label }}

        <span v-if="required" class="text-danger"> *</span>
      </label>
    </template>

    <b-input-group>
      <b-input-group-prepend v-if="icon" is-text>
        <b-icon :icon="icon" />
      </b-input-group-prepend>

      <b-form-select
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :options="options"
        :text-field="textField"
        :value-field="valueField"
        :state="validationState"
        @change="$emit('change', $event)"
      />

      <b-form-invalid-feedback>
        {{ validationMessage }}
      </b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "FormSelect",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Array,
    },
    textField: {
      type: String,
      default: "text",
    },
    valueField: {
      type: String,
      default: "id",
    },
  },
  computed: {
    validationState() {
      return this.errors[0] ? false : null;
    },
    validationMessage() {
      return this.errors[0];
    },
  },
};
</script>

<style></style>
